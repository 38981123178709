.about_container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about-me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: var(--rose);
  display: grid;
  place-items: center;
}

.about_content p {
  margin: 2rem 0 2.6rem;
  color: (var--onyx);
}

/******* Media Queries (Tablets) *******/
@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about_me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about_content p {
    margin: 1rem 0 1.5rem;
  }
}

/******* Media Queries (Mobile) *******/
@media screen and (max-width: 600px) {
  .about_me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  
  .about_content {
    text-align: center;
  }
  
  .about_content p {
    margin: 1.5rem 0;
  }
}