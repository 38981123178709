header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/******** CTA ********/

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/******** SOCIALS ********/

.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  font-size: 1.3rem;
}

.header_socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--onyx);
}

/******** ME ********/

.me {
  width: 90%;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  overflow: hidden;
  padding: 5rem 1.5rem 0 1.5rem;
}

/******** Scroll Down ********/

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.3rem;
}

/******* Media Queries (Tablets) *******/
@media screen and (max-width: 1024px) {
  .me {
    height: 100%;
  }
}

/******* Media Queries (Mobile) *******/
@media screen and (max-width: 600px) {
  header{
    height: 100vh;
  }
  .header_socials, .scroll_down {
    display: none;
  }

  .me {
    width: 100vh;
  }
}